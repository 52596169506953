import { RewriteFrames } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import getConfig from 'next/config';
import * as Sentry from '@sentry/node';
import { getCasID } from './cookies';

// Custom Sentry tag names
export const SENTRY_CAS_TAG = 'cas';
export const SENTRY_ORIGIN_TAG = 'origin';

// Sentry origins - where errors come from
export const SENTRY_ORIGINS = {
  ERROR_PAGE: 'error-page',
  DOCUMENT: '_document',
  OTHER: 'other',
  APOLLO: 'apollo',
};

// Function which creates our standard Sentry scope.
// Currently just adds the 'cas' and 'origin' tags
// to all Sentry logs. These can be overridden in
// individual captureException calls.
export const customSentryScope = (scope) => {
  if (typeof window === 'undefined') return scope;
  const casID = getCasID();
  scope.setTag(SENTRY_CAS_TAG, casID);
  scope.setTag(SENTRY_ORIGIN_TAG, SENTRY_ORIGINS.OTHER);
  return scope;
};

export const initSentry = () => {
  const { publicRuntimeConfig } = getConfig();

  const project = publicRuntimeConfig.SENTRY_NEXT_JS_PROJECT;
  const dsn = publicRuntimeConfig.SENTRY_PUBLIC_NEXT_JS_DSN;
  const release = publicRuntimeConfig.ENVIRONMENT.ReleaseVersion;
  const nextRoot = publicRuntimeConfig.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR;
  const environment = publicRuntimeConfig.ENVIRONMENT.Name;
  const isValidDsn = dsn ? dsn.indexOf('sentry.io') > -1 : false;
  const isValidRelease = (release !== '#{Octopus.Release.Number}' || release !== 'Local' || !release);
  const isSentryEnabled =
    isValidDsn
    && isValidRelease
    && process.env.NODE_ENV === 'production';

  if (process.env.NEXT_IS_SERVER === 'true') {
    // eslint-disable-next-line
    console.log(`
Sentry Next JS Status ${isSentryEnabled ? '✔︝' : '❌ (running locally this is fine)'}
---------------------------------------------------------------------------------
  dsn: ${isValidDsn ? dsn : `invalid dsn: ${dsn}`}
  enabled: ${isSentryEnabled}
  production: ${process.env.NODE_ENV === 'production'}
  environment: ${environment}
  project: ${project}
  release: ${release}
---------------------------------------------------------------------------------
    `);
  }

  if (isSentryEnabled) { // double check to make sure we have a valid dsn injected
    const integrations = [];

    if (process.env.NEXT_IS_SERVER === 'true' && nextRoot) {
      // For Node.js, rewrite Error.stack to use relative paths, so that source
      // maps starting with ~/_next map to files in Error.stack with path
      // app:///_next
      integrations.push(
        new RewriteFrames({
          iteratee: (frame) => {
            // eslint-disable-next-line
            frame.filename = frame.filename.replace(nextRoot, 'app:///');
            // eslint-disable-next-line
            frame.filename = frame.filename.replace('.next', '_next');
            return frame;
          },
        }),
      );
    } else {
      integrations.push(new Integrations.BrowserTracing());
    }

    Sentry.init({
      integrations,
      dsn,
      release,
      environment,
      tracesSampleRate: 1.0,
      initialScope: customSentryScope,
    });
  }
};

export const captureError = (error, origin = SENTRY_ORIGINS.OTHER, extraTags) => {
  const casID = getCasID();
  // // Tag so we can search by it in Sentry
  const { info } = { ...error };
  // We need to remove the info property as it breaks Sentry reporting
  // This is just used to pass extra info into Sentry through a throw
  // Can't be copied to a new object as Errors can't be cloned
  // eslint-disable-next-line no-param-reassign
  delete error.info;
  Sentry.captureException(error, {
    tags: {
      [SENTRY_CAS_TAG]: casID,
      [SENTRY_ORIGIN_TAG]: origin,
      ...extraTags,
    },
    extra: info,
  });
};

export const captureEvent = (event, origin = SENTRY_ORIGINS.OTHER) => {
  const casID = getCasID();
  // Tag so we can search by it in Sentry
  Sentry.captureEvent(event, {
    tags: {
      [SENTRY_CAS_TAG]: casID,
      [SENTRY_ORIGIN_TAG]: origin,
    },
  });
};

/**
 * Build an error object to send to Sentry using the 'captureError' helper
 * @param {string} message The actual error message
 * @param {Object} info An object with additional data to be logged to Sentry
 * @returns {Error} A JS Error class with an added 'info' field
 */
export const buildError = (message, info) => {
  const error = new Error(message);
  error.info = info;
  return error;
};

export default null;
