import { multiply } from '../libs/calculate';

// Brand
const COLOR_AMBER_ORANGE = '#D25D15';
const COLOR_AMBER_RED = '#B31F29';
const COLOR_BERRY_RED = '#AC1640';
const COLOR_BERRY_RED_DARK = '#8B2432';
const COLOR_FOREST_GREEN = '#2E9940';
const COLOR_LAKE_GREEN_ORIGINAL = '#4b938e';
const COLOR_LAKE_GREEN_DARK = '#217473';
// Brand End

const COLOR_OCEAN_BLUE = '#2C94A7';
const COLOR_OCEAN_DARK_BLUE = '#006070';
const COLOR_GRANITE_GRAY = '#3C3C3C';
const COLOR_GRANITE_GREEN = '#2E444E';
const COLOR_WARM_BACKGROUND = '#F0F0EE';
const COLOR_CYAN_DARK = '#007575';
const COLOR_MUTED_GREEN = '#4c9d9e';
const COLOR_PEACH = '#FBF0E9';
const COLOR_GRAY_DARK = '#333333';

// #333 at 60% opacity
const COLOR_DARK_GRAY_CLEAR = '#33333399';

// #007575 at 30% opacity
const COLOR_CYAN_DARK_CLEAR = '#0075754D';

const MOBILE = 0;
const MOBILE_MEDIUM = 375;
const MOBILE_LARGE = 425;
const TABLET = 768;
const DESKTOP = 1024;
const DESKTOP_LARGE = 1440;

const PADDING = '1rem';

export default {
  COLOR_BRAND_PRIMARY: COLOR_LAKE_GREEN_ORIGINAL,
  COLOR_BRAND_PRIMARY_DARK: COLOR_LAKE_GREEN_DARK,
  COLOR_BRAND_PRIMARY_LIGHT: '#A5C9C6',
  COLOR_TEXT_HEADING: COLOR_GRANITE_GRAY,
  COLOR_TEXT_BODY: COLOR_GRANITE_GRAY,
  COLOR_TEXT_MUTED: '#999999',
  COLOR_GRAY: '#AAAAAA',
  COLOR_GRAY_DARK,
  COLOR_GRAY_DARKEST: '#111111',
  COLOR_GRAY_LIGHT: '#D1D2D1',
  COLOR_GRAY_LIGHTER: COLOR_WARM_BACKGROUND,
  COLOR_GRAY_LIGHTEST: COLOR_WARM_BACKGROUND,
  COLOR_WHITE: '#FFFFFF',
  COLOR_WHITE_DARK: '#F6F6F6',
  COLOR_POPOVER_DESKTOP: COLOR_GRANITE_GRAY,
  COLOR_AMBER_ORANGE,
  COLOR_AMBER_RED,
  COLOR_AMBER_RED_DARK: '#9d1b24',
  COLOR_BERRY_RED,
  COLOR_BERRY_RED_DARK,
  COLOR_FOREST_GREEN,
  COLOR_LAKE_GREEN_ORIGINAL,
  COLOR_LAKE_GREEN_DARK,
  COLOR_OCEAN_BLUE,
  COLOR_GRANITE_GRAY,
  COLOR_GRANITE_GREEN,
  COLOR_CYAN_DARK,
  COLOR_MUTED_GREEN,
  COLOR_PEACH,
  COLOR_CYAN_DARK_CLEAR,

  COLOR_DANGER: COLOR_AMBER_RED,
  COLOR_INFO: COLOR_OCEAN_BLUE,
  COLOR_SUCCESS: COLOR_FOREST_GREEN,
  COLOR_WARNING: COLOR_AMBER_ORANGE,

  COLOR_CAMPSITE_TYPE_1: COLOR_AMBER_ORANGE,
  COLOR_CAMPSITE_TYPE_2: COLOR_BERRY_RED,
  COLOR_CAMPSITE_TYPE_3: COLOR_LAKE_GREEN_ORIGINAL,
  COLOR_CAMPSITE_TYPE_4: COLOR_OCEAN_BLUE,
  COLOR_CAMPSITE_TYPE_5: COLOR_GRANITE_GRAY,
  COLOR_CAMPSITE_TYPE_6: COLOR_AMBER_ORANGE,

  COLOR_EVENT_TYPE_2: COLOR_AMBER_ORANGE,
  COLOR_EVENT_TYPE_3: COLOR_FOREST_GREEN,
  COLOR_EVENT_TYPE_4: COLOR_OCEAN_DARK_BLUE,

  /**
   * Typography
   */
  TEXT_HEADING_FONT: '"ElliotBold", sans-serif',
  TEXT_BODY_FONT: 'Arial, sans-serif',

  TEXT_BODY_SIZE: '0.875rem',
  TEXT_BODY_WEIGHT: 400,

  TEXT_META_SIZE: '0.75rem',
  TEXT_META_WEIGHT: 400,

  /**
   * Ratios
   */
  RATIO_2_1: 'calc((1 / 2) * 100%)',
  RATIO_5_3: 'calc((3 / 5) * 100%)',
  RATIO_16_9: 'calc((9 / 16) * 100%)',

  /**
   * Layouts
   */
  ONE_PANEL: '100%',
  TWO_PANEL: '50%',

  TWO_PANEL_WEIGHTED_LEFT_1: '75%',
  TWO_PANEL_WEIGHTED_LEFT_2: '25%',

  TWO_PANEL_WEIGHTED_RIGHT_1: '37.5%',
  TWO_PANEL_WEIGHTED_RIGHT_2: '62.5%',

  SECTION_BACKGROUND_COLOR: COLOR_WARM_BACKGROUND,
  PAGE_GUTTER: '2rem',

  /**
   * Sizes
   */
  BUTTON_SIZE_XS: '1.5rem',
  BUTTON_SIZE_SMALL: 'calc(2rem - 4px)', // with border making button height 2rem;
  BUTTON_SIZE_MEDIUM: '2.5rem',
  BUTTON_SIZE_LARGE: '3.125rem',
  BUTTON_SIZE_XL: '4rem',

  CONTAINER_WIDTH: '820px',

  MODAL_SIZE_SMALL: 440,
  MODAL_SIZE_MEDIUM: TABLET,

  FIELD_SIZE_XS: '1.5rem',
  FIELD_SIZE_SMALL: '2.25rem',
  FIELD_SIZE_MEDIUM: '2.75rem',
  FIELD_SIZE_LARGE: '3.125rem',

  CHECKOUT_MAX_WIDTH: '972px',
  BORDER_RADIUS: '5px',

  /**
   * Breakpoints
   */
  MOBILE,
  MOBILE_MEDIUM,
  MOBILE_LARGE,
  TABLET,
  DESKTOP,
  DESKTOP_LARGE,
  BREAKPOINTS: [
    { key: 'MOBILE', value: MOBILE },
    { key: 'MOBILE_MEDIUM', value: MOBILE_MEDIUM },
    { key: 'MOBILE_LARGE', value: MOBILE_LARGE },
    { key: 'TABLET', value: TABLET },
    { key: 'DESKTOP', value: DESKTOP },
    { key: 'DESKTOP_LARGE', value: DESKTOP_LARGE },
  ],

  PADDING,
  PADDING_LARGE: multiply(PADDING, 1.25),
  PADDING_MEDIUM: multiply(PADDING, 1.125),
  PADDING_SMALL: multiply(PADDING, 0.75),
  PADDING_XL: multiply(PADDING, 1.875),
  PADDING_XS: multiply(PADDING, 0.5),

  /**
   * Basket
   */
  BASKET_TOGGLE_WIDTH: '3rem',

  // Calendar colours
  COLOR_CALENDAR_AVAILABLE: '#EAF4F1',
  COLOR_CALENDAR_SELECTED: COLOR_CYAN_DARK,
  COLOR_CALENDAR_SELECTED_RANGE: COLOR_LAKE_GREEN_ORIGINAL,
  COLOR_CALENDAR_BOOKED: COLOR_CYAN_DARK_CLEAR,
  // #EBEBEB at 60% opacity (99 hex -> decimal)
  COLOR_CALENDAR_FULL_FILL: '#EBEBEB99',
  COLOR_CALENDAR_FULL_TEXT: COLOR_DARK_GRAY_CLEAR,
  COLOR_CALENDAR_CLOSED_FILL: COLOR_DARK_GRAY_CLEAR,

  // Amends colours
  COLOR_BOOKING_SELECTED: '#EBEBEB',

  // Scrollbar
  SCROLL_BAR_COLOR: COLOR_CYAN_DARK,
  SCROLL_BAR_BACKGROUND: '#EFEFEF',
  SCROLL_BAR_BORDER_COLOR: COLOR_GRAY_DARK,
  SCROLL_BAR_BORDER_RADIUS: '0 0 50px 50px',
  SCROLL_BAR_WIDTH: '12px',
  SCROLL_BAR_BORDER_WIDTH: '1px',
};
